.styleButton button{
position: absolute;
right: 20px;
}
.couponDialog input {
	width:342px;
	margin-bottom: 10px;
}
.image-upload>input {
	display: none;
	margin: 0;
	padding: 0;
  }
  .image-upload {
	display: inline;
	padding: 0;
	margin: 0;
	background-color: #D5641C;
  }
  .couponDialogBox .MuiDialog-paperWidthSm{
	  width:90% !important;
  }